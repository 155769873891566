'use strict'
const remoteStuctureFetcher = require('@wix/blocks-widget-services/remoteStructureFetcher')

const {getAppDescriptor, getWidgetStructureByAppData, getWidgetStructure} = remoteStuctureFetcher

module.exports = {
    getWidgetStructure,
    getAppDescriptor,
    getWidgetStructureByAppData
}
